.status {
    color: #BE783B;
    font-weight: 600;


}

.img-size {
    width: 40px;
    height: 40px;
}

.font-weight-bold {
    font-weight: 600;
    font-style: bold;
}

.shift {
    margin-left: 10px;
}